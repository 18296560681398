import TYPES from '@/types';

import Vue from 'vue';

// Application
import GetInternetStatusService
  from '@/modules/internet-status/application/services/get-internet-status-service';
import GetOnBoardingIdentityVerificationStatusQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-on-boarding-identity-verification-status-query';
import GetMatiVerificationQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-mati-verification-query';

// Domain
import {
  MatiVerificationEntity,
} from '@/modules/on-boarding/identity-verification/domain/entities/mati-verification-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { EventBus } from '@/modules/shared/domain/bus/event-bus';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class VerifyingViewModel {
  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  readonly getInternetStatusService!: GetInternetStatusService;

  @Inject(TYPES.GET_ON_BOARDING_IDENTITY_VERIFICATION_STATUS_QUERY)
  // eslint-disable-next-line max-len
  readonly getOnBoardingIdentityVerificationStatusQuery!: GetOnBoardingIdentityVerificationStatusQuery;

  @Inject(TYPES.GET_ON_BOARDING_MATI_VERIFICATION_QUERY)
  private readonly get_mati_verification_query!: GetMatiVerificationQuery;

  @Inject(TYPES.EVENT_BUS)
  private readonly event_bus!: EventBus;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  readonly i18n_namespace = 'sign-up.verify-identity.verifying';

  verification_status = '';

  loading = false;

  mati_verification: MatiVerificationEntity = {
    id: '',
    customer_id: '',
    status: '',
    attempt_counter: 0,
    created_at: '',
    updated_at: '',
  };

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }

  get identityVerificationStatus() {
    return this.getOnBoardingIdentityVerificationStatusQuery.execute();
  }

  get identityVerificationSucceed() {
    const last_step = this.identityVerificationStatus;
    const current_flow_id = this.getCurrentMatiFlow();

    if (current_flow_id === last_step.mati_flow_id || !last_step.mati_flow_id) {
      if ('success' in last_step && !last_step.success) {
        this.unexpectedError();
      }

      if (last_step.name === 'verification_expired') {
        this.verificationExpired();
      }

      if (last_step.percent === 100) {
        this.verification_status = last_step.status || '';
        if (this.verification_status === 'has_error') {
          this.hasError();
        }

        if (this.verification_status === 'manual_review_required') {
          this.manualReviewRequired();
        }
      }
    }

    return (
      (
        this.identityVerificationStatus.status === 'verified'
        && this.identityVerificationStatus.percent === 100
      )
      || this.mati_verification.status === 'verified'
      || this.mati_verification.status === 'manually_verified'
    );
  }

  loadMatiVerificationStatus = async () => {
    try {
      this.mati_verification = await this.get_mati_verification_query.execute(true);
      if (this.mati_verification.status === 'verified' || this.mati_verification.status === 'manually_verified') {
        this.identityVerificationStatus.percent = 100;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_mati_verification'));
    }
  }

  get canContinue() {
    return (
      this.identityVerificationSucceed
        && this.internetStatus
    );
  }

  getCurrentMatiFlow = () => sessionStorage.getItem('mati_flow_id');

  nextStep = async () => {
    this.view.$emit('nextStep');
  }

  unexpectedError = () => {
    this.view.$emit('unexpectedError');
  }

  hasError = () => {
    this.view.$emit('hasError');
  }

  verificationExpired = () => {
    this.view.$emit('verificationExpired');
  }

  manualReviewRequired = () => {
    this.view.$emit('manualReviewRequired');
  }

  initialize = async () => {
    this.loading = true;
    await this.loadMatiVerificationStatus();
    this.loading = false;
  }
}
