




























import { Vue, Component, PropSync } from 'vue-property-decorator';

import IdentityVerificationViewModel from '@/vue-app/view-models/components/identity-verification-view-model';

@Component({ name: 'IdentityVerification' })
export default class IdentityVerification extends Vue {
  @PropSync('source', { type: String, required: true })
  synced_source!: string;

  identity_verification_view_model = Vue.observable(new IdentityVerificationViewModel(this));

  created() {
    const mati_script = document.createElement('script');

    mati_script.setAttribute('src', 'https://web-button.mati.io/button.js');
    document.head.appendChild(mati_script);
  }
}
